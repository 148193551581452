<template>
  <VueCtkDateTimePicker
    v-model="valueDay"
    v-bind="{
      range: range,
      format: format,
      formatted: formatted,
      label: label,
      noLabel: valueDay ? true : false,
      noShortcuts: noShortcuts
    }"
  ></VueCtkDateTimePicker>
</template>

<script>
export default {
  name: 'DateRangeDay',
  mixins: [],
  props: {
    range: {
      type: Boolean,
      default: true
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    formatted: {
      type: String,
      default: 'll'
    },
    label: {
      type: String,
      default: ''
    },
    noShortcuts: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data() {
    return {
      valueDay: this.value ? this.value : ''
    }
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {
    valueDay(val) {
      if (val.end) {
        this.$emit('dayRange', val)
      }
    }
  },
  validations: {}
}
</script>

<style scoped></style>
