var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VueCtkDateTimePicker',_vm._b({model:{value:(_vm.valueDay),callback:function ($$v) {_vm.valueDay=$$v},expression:"valueDay"}},'VueCtkDateTimePicker',{
    range: _vm.range,
    format: _vm.format,
    formatted: _vm.formatted,
    label: _vm.label,
    noLabel: _vm.valueDay ? true : false,
    noShortcuts: _vm.noShortcuts
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }